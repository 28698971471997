import React from "react"
//import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import TeamPage from "../components/team"
import PartnerPage from "../components/partners"
import Teamimage from "./../images/7ba4dd7a-5ade-4349-8d08-bc46a1289439.jpg"

const Team = () => (
  <Layout>

    <SEO title="Our team" />
    <div>
      <div className="container pt-4">
        <div className="row justify-content-center text-center align-items-center">
          <div className="col">
            <h1 className="display-1">Our Team</h1>
          </div>
        </div>
      </div>
    </div>
    <TeamPage />

    
  </Layout>
)

export default Team
